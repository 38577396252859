/* Sidebar */
/* Primary */
/* Accent */
/* Topbar */
/* Submenu */
/* Default MenuItem */
/* Hover MenuItem */
/* Active MenuItem */
/* Dark Default MenuItem */
/* Dark Hover MenuItem */
/* Dark Active MenuItem */
/******************************/
/*           Common           */
/******************************/
/* Predefined Colors */
/* Add your variable customizations of layout here */
/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/roboto-v15-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"), url("../fonts/roboto-v15-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v15-latin-300.woff2") format("woff2"), url("../fonts/roboto-v15-latin-300.woff") format("woff"), url("../fonts/roboto-v15-latin-300.ttf") format("truetype"), url("../fonts/roboto-v15-latin-300.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto-v15-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"), url("../fonts/roboto-v15-latin-regular.eot#iefix") format("embedded-opentype"), url("../fonts/roboto-v15-latin-regular.woff2") format("woff2"), url("../fonts/roboto-v15-latin-regular.woff") format("woff"), url("../fonts/roboto-v15-latin-regular.ttf") format("truetype"), url("../fonts/roboto-v15-latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto-v15-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Bold"), local("Roboto-Bold"), url("../fonts/roboto-v15-latin-700.eot#iefix") format("embedded-opentype"), url("../fonts/roboto-v15-latin-700.woff2") format("woff2"), url("../fonts/roboto-v15-latin-700.woff") format("woff"), url("../fonts/roboto-v15-latin-700.ttf") format("truetype"), url("../fonts/roboto-v15-latin-700.svg#Roboto") format("svg");
  /* Legacy iOS */
}
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/MaterialIcons-Regular.eot");
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url("../fonts/MaterialIcons-Regular.woff2") format("woff2"), url("../fonts/MaterialIcons-Regular.woff") format("woff"), url("../fonts/MaterialIcons-Regular.ttf") format("truetype");
}
.splash-screen {
  width: 100%;
  min-height: 100%;
  background-color: #37474F;
  position: absolute;
}

.splash-loader-container {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -32px;
  margin-top: -32px;
}

.splash-loader {
  animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: #4285F4;
  }
  25% {
    stroke: #DE3E35;
  }
  50% {
    stroke: #F7C223;
  }
  75% {
    stroke: #1B9A59;
  }
  100% {
    stroke: #4285F4;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}
/* Utils */
.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

*[hidden] {
  display: none;
}

.card {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: #ffffff;
  padding: 16px;
  margin-bottom: 16px;
  box-sizing: border-box;
}
.card.card-w-title {
  padding-bottom: 32px;
}
.card h1 {
  font-size: 24px;
  font-weight: 400;
  margin: 24px 0;
}
.card h1:first-child {
  margin-top: 16px;
}
.card h2 {
  font-size: 22px;
  font-weight: 400;
}
.card h3 {
  font-size: 20px;
  font-weight: 400;
}
.card h4 {
  font-size: 18px;
  font-weight: 400;
}

.nopad {
  padding: 0;
}
.nopad .ui-panel-content {
  padding: 0;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
.fadeInDown {
  -webkit-animation: fadeInDown 5s;
  /* Safari 4.0 - 8.0 */
  animation: fadeInDown 5s;
}

.fadeOutUp {
  -webkit-animation: fadeOutUp 0.3s;
  /* Safari 4.0 - 8.0 */
  animation: fadeOutUp 0.3s;
}

.ui-shadow-1 {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.ui-shadow-2 {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ui-shadow-3 {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.ui-shadow-4 {
  -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ui-shadow-5 {
  -webkit-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.ui-g {
  -ms-flex-wrap: wrap;
}
.ui-g.form-group > div {
  padding: 1em;
}
.ui-g.form-group-m > div {
  padding: 1em;
}

/* Ripple Effect Style like Google Material Buttons Effect*/
.ripplelink {
  /* display:block; */
  /*color:#fff;*/
  text-decoration: none;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 0;
}

.ripplelink:hover {
  /*z-index:1000;*/
}

.ink {
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 100%;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.ripple-animate {
  -webkit-animation: ripple 0.65s linear;
  -moz-animation: ripple 0.65s linear;
  -ms-animation: ripple 0.65s linear;
  -o-animation: ripple 0.65s linear;
  animation: ripple 0.65s linear;
}

@-webkit-keyframes ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(2.5);
  }
}
@-moz-keyframes ripple {
  100% {
    opacity: 0;
    -moz-transform: scale(2.5);
  }
}
@-o-keyframes ripple {
  100% {
    opacity: 0;
    -o-transform: scale(2.5);
  }
}
@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}
.dashboard .task-box {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
}
.dashboard .task-box .task-box-header {
  padding: 8px 14px;
}
.dashboard .task-box .task-box-header:before, .dashboard .task-box .task-box-header:after {
  content: "";
  display: table;
}
.dashboard .task-box .task-box-header:after {
  clear: both;
}
.dashboard .task-box .task-box-header i {
  float: right;
  color: #ffffff;
}
.dashboard .task-box .task-box-content {
  background-color: #ffffff;
  padding: 8px 14px;
}
.dashboard .task-box .task-box-content h3 {
  font-weight: bold;
  font-size: 14px;
  margin: 14px 0 7px 0;
  padding: 0;
}
.dashboard .task-box .task-box-content p {
  color: #757575;
  margin: 0 0 28px 0;
  padding: 0;
}
.dashboard .task-box .task-box-footer {
  background-color: #ffffff;
  padding: 8px 14px;
}
.dashboard .task-box .task-box-footer:before, .dashboard .task-box .task-box-footer:after {
  content: "";
  display: table;
}
.dashboard .task-box .task-box-footer:after {
  clear: both;
}
.dashboard .task-box .task-box-footer img {
  width: 32px;
  float: right;
  margin-left: 4px;
}
.dashboard .task-box .task-box-footer .task-status {
  -moz-border-radius: 9px;
  -webkit-border-radius: 9px;
  border-radius: 9px;
  padding: 2px 8px;
  color: #ffffff;
}
.dashboard .task-box.task-box-1 .task-box-header {
  background-color: #e91e63;
}
.dashboard .task-box.task-box-1 .task-box-footer .task-status {
  background-color: #e91e63;
}
.dashboard .task-box.task-box-2 .task-box-header {
  background-color: #ffc107;
}
.dashboard .task-box.task-box-2 .task-box-footer .task-status {
  background-color: #ffc107;
}
.dashboard .task-box.task-box-3 .task-box-header {
  background-color: #00bcd4;
}
.dashboard .task-box.task-box-3 .task-box-footer .task-status {
  background-color: #00bcd4;
}
.dashboard .overview-box {
  text-align: center;
  color: #ffffff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
}
.dashboard .overview-box .overview-box-header {
  height: 24px;
}
.dashboard .overview-box .overview-box-content {
  padding: 8px 14px 14px 14px;
}
.dashboard .overview-box .overview-box-content .overview-box-icon {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  margin-top: -28px;
}
.dashboard .overview-box .overview-box-content .overview-box-icon i {
  line-height: inherit;
  font-size: 28px;
}
.dashboard .overview-box .overview-box-content .overview-box-title {
  font-size: 16px;
}
.dashboard .overview-box .overview-box-content .overview-box-count {
  font-size: 24px;
}
.dashboard .overview-box.overview-box-1 .overview-box-header {
  background-color: #f06292;
}
.dashboard .overview-box.overview-box-1 .overview-box-content {
  background-color: #e91e63;
}
.dashboard .overview-box.overview-box-1 .overview-box-content .overview-box-icon {
  background-color: #e91e63;
}
.dashboard .overview-box.overview-box-2 .overview-box-header {
  background-color: #4dd0e1;
}
.dashboard .overview-box.overview-box-2 .overview-box-content {
  background-color: #00bcd4;
}
.dashboard .overview-box.overview-box-2 .overview-box-content .overview-box-icon {
  background-color: #00bcd4;
}
.dashboard .overview-box.overview-box-3 .overview-box-header {
  background-color: #ffd54f;
}
.dashboard .overview-box.overview-box-3 .overview-box-content {
  background-color: #ffc107;
}
.dashboard .overview-box.overview-box-3 .overview-box-content .overview-box-icon {
  background-color: #ffc107;
}
.dashboard .overview-box.overview-box-4 .overview-box-header {
  background-color: #9e9e9e;
}
.dashboard .overview-box.overview-box-4 .overview-box-content {
  background-color: #616161;
}
.dashboard .overview-box.overview-box-4 .overview-box-content .overview-box-icon {
  background-color: #616161;
}
.dashboard .task-list {
  overflow: hidden;
}
.dashboard .task-list .ui-panel {
  min-height: 340px;
  border: 0 none;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
}
.dashboard .task-list .ui-panel-content {
  padding: 10px 0 0 0 !important;
}
.dashboard .task-list button {
  margin-top: -28px;
  margin-right: 10px;
  float: right;
}
.dashboard .task-list .task-list-container {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.dashboard .task-list .task-list-container > li {
  padding: 10px 14px;
  border-bottom: 1px solid #dbdbdb;
}
.dashboard .task-list .task-list-container > li:first-child {
  margin-top: 10px;
}
.dashboard .task-list .task-list-container .ui-chkbox {
  vertical-align: middle;
  margin-right: 5px;
}
.dashboard .task-list .task-list-container .task-name {
  vertical-align: middle;
}
.dashboard .task-list .task-list-container i {
  color: #757575;
  float: right;
}
.dashboard .contact-form {
  overflow: hidden;
}
.dashboard .contact-form .ui-panel {
  min-height: 340px;
  border: 0 none;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
}
.dashboard .contact-form .ui-g-12 {
  padding: 16px 10px;
}
.dashboard .contact-form .ui-button {
  margin-top: 20px;
}
.dashboard .messages {
  overflow: hidden;
}
.dashboard .messages .ui-panel {
  min-height: 340px;
  border: 0 none;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
}
.dashboard .messages .ui-panel-content {
  padding: 15px 0 10px 0 !important;
}
.dashboard .messages ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.dashboard .messages ul li {
  border-bottom: 1px solid #d8d8d8;
}
.dashboard .messages ul li > div {
  display: inline-block;
  padding: 9px;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  display: block;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
.dashboard .messages ul li > div:before, .dashboard .messages ul li > div:after {
  content: "";
  display: table;
}
.dashboard .messages ul li > div:after {
  clear: both;
}
.dashboard .messages ul li > div img {
  float: left;
}
.dashboard .messages ul li > div > div {
  float: left;
  margin-left: 10px;
}
.dashboard .messages ul li > div > div .name {
  font-size: 14px;
  font-weight: 700;
  display: block;
  color: #212121;
}
.dashboard .messages ul li > div > div .message {
  font-size: 14px;
  color: #757575;
}
.dashboard .messages ul li > div button {
  position: absolute;
  top: 15px;
}
.dashboard .messages ul li > div button.message-btn {
  right: 20px;
}
.dashboard .messages ul li > div button.remove-btn {
  right: 60px;
}
.dashboard .messages ul li > div:hover {
  cursor: pointer;
  background-color: #e8e8e8;
}
.dashboard .messages ul li:last-child {
  border: 0;
}
.dashboard .chat .ui-panel {
  border: 0 none;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
}
.dashboard .chat .ui-panel-content {
  padding: 0 !important;
}
.dashboard .chat .ui-tabview {
  border-color: transparent;
}
.dashboard .chat .ui-tabview .ui-tabview-panel {
  border: 0 none;
}
.dashboard .chat .chat-messages {
  padding: 12px;
  margin: 0;
  list-style-type: none;
}
.dashboard .chat .chat-messages li {
  padding: 6px 0;
}
.dashboard .chat .chat-messages li:before, .dashboard .chat .chat-messages li:after {
  content: "";
  display: table;
}
.dashboard .chat .chat-messages li:after {
  clear: both;
}
.dashboard .chat .chat-messages li img {
  width: 36px;
  float: left;
}
.dashboard .chat .chat-messages li span {
  padding: 6px 12px;
  float: left;
  display: inline-block;
  margin: 4px 0;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.dashboard .chat .chat-messages li.message-from img, .dashboard .chat .chat-messages li.message-from span {
  float: left;
}
.dashboard .chat .chat-messages li.message-from img {
  margin-right: 8px;
}
.dashboard .chat .chat-messages li.message-from span {
  background-color: #e8eaf6;
}
.dashboard .chat .chat-messages li.message-own img, .dashboard .chat .chat-messages li.message-own span {
  float: right;
}
.dashboard .chat .chat-messages li.message-own img {
  margin-left: 8px;
}
.dashboard .chat .chat-messages li.message-own span {
  background: #c8e6c9;
  color: #000000;
}
.dashboard .chat .new-message {
  height: 40px;
  border-top: 1px solid #dce2e7;
  color: #afafc0;
}
.dashboard .chat .new-message .message-attachment {
  display: inline-block;
  border-right: 1px solid #dce2e7;
  width: 40px;
  line-height: 40px;
  height: 100%;
  text-align: center;
}
.dashboard .chat .new-message .message-attachment i {
  line-height: inherit;
  font-size: 24px;
}
.dashboard .chat .new-message .message-input {
  position: relative;
  top: -6px;
  width: calc(100% - 100px);
  display: inline-block;
}
.dashboard .chat .new-message .message-input input {
  border: 0 none;
  font-size: 14px;
  width: 100%;
  background-color: transparent;
  outline: 0 none;
  color: #757575;
}
.dashboard .global-sales .header-helper {
  float: right;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.dashboard .global-sales .ui-panel {
  border: 0 none;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
}
.dashboard .global-sales .ui-panel-content {
  padding: 14px 9px 0px 9px;
}
.dashboard .global-sales table {
  width: 100%;
  border-collapse: collapse;
}
.dashboard .global-sales table th {
  font-weight: 700;
  text-align: left;
  padding: 8px 5px;
}
.dashboard .global-sales table tbody tr {
  border-top: 1px solid #dbdbdb;
}
.dashboard .global-sales table tbody tr img {
  width: 36px;
  height: 36px;
}
.dashboard .global-sales table tbody tr td {
  padding: 8px 5px;
}
.dashboard .global-sales table tbody tr td:nth-child(1) {
  font-weight: 700;
  text-align: center;
}
.dashboard .global-sales table tbody tr td:nth-child(3) {
  font-weight: 700;
}
.dashboard .global-sales table tbody tr td:nth-child(7) {
  text-align: right;
}
.dashboard .global-sales table tbody tr td:nth-child(7) button {
  margin-left: 10px;
}
.dashboard .global-sales table tbody tr td .up-arrow {
  color: #cddc39;
}
.dashboard .global-sales table tbody tr td .down-arrow {
  color: #e91e63;
}
.dashboard .status-bars ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.dashboard .status-bars ul li {
  padding: 8px 14px;
  position: relative;
}
.dashboard .status-bars ul li span {
  position: absolute;
  right: 36px;
  top: 8px;
}
.dashboard .status-bars ul li i {
  position: absolute;
  right: 4px;
  top: 4px;
}
.dashboard .status-bars .status-bar {
  height: 18px;
  width: 75%;
  background-color: #d8d8d8;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.dashboard .status-bars .status-bar .status-bar-value {
  height: 100%;
  color: #ffffff;
  text-align: right;
  padding-right: 4px;
  padding-top: 1px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.dashboard .status-bars .status-bar.status-bar-1 .status-bar-value {
  background-color: #e91e63;
}
.dashboard .status-bars .status-bar.status-bar-1 ~ i {
  color: #e91e63;
}
.dashboard .status-bars .status-bar.status-bar-2 .status-bar-value {
  background-color: #00bcd4;
}
.dashboard .status-bars .status-bar.status-bar-2 ~ i {
  color: #00bcd4;
}
.dashboard .status-bars .status-bar.status-bar-3 .status-bar-value {
  background-color: #ffc107;
}
.dashboard .status-bars .status-bar.status-bar-3 ~ i {
  color: #ffc107;
}
.dashboard .status-bars .status-bar.status-bar-4 .status-bar-value {
  background-color: #cddc39;
}
.dashboard .status-bars .status-bar.status-bar-4 ~ i {
  color: #cddc39;
}
.dashboard .status-bars .status-bar.status-bar-5 .status-bar-value {
  background-color: #ff9800;
}
.dashboard .status-bars .status-bar.status-bar-5 ~ i {
  color: #ff9800;
}
.dashboard .image-box .card {
  padding: 0;
}
.dashboard .image-box .card img {
  width: 100%;
}
.dashboard .image-box .card .image-box-content {
  padding: 16px;
}
.dashboard .image-box .card .image-box-content h3 {
  font-weight: 700;
  margin-top: 0;
}
.dashboard .image-box .card .image-box-content .image-box-tag {
  width: 40px;
  text-align: left;
  color: #ffffff;
  background-color: #e91e63;
  padding: 0 8px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.dashboard .image-box .card .image-box-footer {
  text-align: right;
}
.dashboard .user-card {
  border: 1px solid #dbdbdb;
  padding: 0;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.dashboard .user-card .user-card-header {
  height: 100px;
  overflow: hidden;
}
.dashboard .user-card .user-card-header img {
  width: 100%;
}
.dashboard .user-card .user-card-content {
  min-height: 340px;
  background-color: #ffffff;
  position: relative;
}
.dashboard .user-card .user-card-content img {
  position: absolute;
  top: -90px;
  left: 24px;
}
.dashboard .user-card .user-card-content .ui-button {
  position: absolute;
  width: 36px;
  height: 36px;
  top: -18px;
  right: 24px;
}
.dashboard .user-card .user-card-content .user-card-name {
  font-size: 20px;
  color: #212121;
  position: absolute;
  top: -60px;
  margin-left: 110px;
  font-weight: 700;
  white-space: nowrap;
}
.dashboard .user-card .user-card-content .user-detail {
  text-align: left;
}
.dashboard .user-card .user-card-content .user-detail ul {
  padding: 0px 0 32px 0;
  margin: 0;
  list-style-type: none;
}
.dashboard .user-card .user-card-content .user-detail ul li {
  padding: 16px 24px;
  border-top: 1px solid #dbdbdb;
}
.dashboard .user-card .user-card-content .user-detail ul li:last-child {
  border-bottom: 1px solid #dbdbdb;
}
.dashboard .user-card .user-card-content .user-detail ul li i {
  font-size: 24px;
  margin-right: 8px;
  width: 32px;
  vertical-align: middle;
  color: #757575;
}
.dashboard .user-card .user-card-content .user-detail ul li .project-title {
  font-weight: 700;
  margin-right: 8px;
}
.dashboard .user-card .user-card-content .user-detail ul li .project-detail {
  color: #757575;
}
.dashboard .user-card .user-card-content .user-detail ul li .project-progressbar {
  display: inline-block;
  width: 100px;
  background-color: #dbdbdb;
  float: right;
  margin-top: 12px;
}
.dashboard .user-card .user-card-content .user-detail ul li .project-progressbar .project-progressbar-value {
  background-color: #607D8B;
  height: 4px;
}
.dashboard .map .ui-panel {
  border: 0 none;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
}
.dashboard .map .ui-panel .ui-panel-content {
  padding: 8px;
}
.dashboard .map .ui-panel .ui-panel-content img {
  width: 100%;
}
.dashboard .schedule .ui-panel {
  border: 0 none;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
}
.dashboard .schedule .ui-panel .fc-today-button {
  display: none;
}

@media (max-width: 640px) {
  .dashboard .status-bars .status-bar {
    width: 65%;
  }
  .dashboard .global-sales table tbody tr td:nth-child(7) {
    text-align: left;
  }
  .dashboard .global-sales table tbody tr td:nth-child(7) button {
    display: block;
    margin-left: 0;
  }
  .dashboard .global-sales table tbody tr td:nth-child(7) button:first-child {
    margin-bottom: 4px;
  }
}
.login-body {
  background-image: url("../images/login/login-bg.jpg");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.login-body .login-panel {
  width: 550px;
  height: 480px;
  background-color: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -275px;
  margin-top: -240px;
  padding: 0;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
}
.login-body .login-panel .login-panel-header {
  background-color: #607D8B;
  text-align: center;
  padding: 8px 14px;
}
.login-body .login-panel .login-panel-header img {
  vertical-align: middle;
  width: 50px;
}
.login-body .login-panel .login-panel-content {
  padding: 50px 100px;
}
.login-body .login-panel .login-panel-content h1 {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 16px;
  text-align: center;
}
.login-body .login-panel .login-panel-content .ui-g-12, .login-body .login-panel .login-panel-content .ui-g-6 {
  padding: 1em;
}
.login-body .login-panel .login-panel-content .ui-g-12:last-child, .login-body .login-panel .login-panel-content .ui-g-6:last-child {
  text-align: center;
}
.login-body .login-panel .login-panel-content .ui-g-12:last-child a, .login-body .login-panel .login-panel-content .ui-g-6:last-child a {
  color: #7CB342;
}
.login-body .login-panel .login-panel-content .password-reset {
  text-align: right;
}
.login-body .login-panel .login-panel-content .password-reset a {
  color: #757575;
}
.login-body .login-panel .login-panel-content .ui-chkbox-label {
  margin: 0 0 0 8px;
  vertical-align: middle;
}

@media (max-width: 640px) {
  .login-body .login-panel {
    left: 0;
    margin-left: 0;
    width: 100%;
  }
  .login-body .login-panel .login-panel-content {
    padding: 50px 25px;
  }
}
.exception-body {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.exception-body.error {
  background-image: url("../images/exception/error-bg.jpg");
}
.exception-body.error .exception-panel .exception-code {
  background-color: #e91e63;
}
.exception-body.error .exception-panel .exception-code img {
  margin-left: -194px;
}
.exception-body.error .exception-panel .exception-icon {
  background-color: #e91e63;
}
.exception-body.notfound {
  background-image: url("../images/exception/notfound-bg.jpg");
}
.exception-body.notfound .exception-panel .exception-code {
  background-color: #e91e63;
}
.exception-body.notfound .exception-panel .exception-code img {
  margin-left: -206px;
}
.exception-body.notfound .exception-panel .exception-icon {
  background-color: #e91e63;
}
.exception-body.accessdenied {
  background-image: url("../images/exception/access-bg.jpg");
}
.exception-body.accessdenied .exception-panel .exception-code {
  background-color: #ffb300;
}
.exception-body.accessdenied .exception-panel .exception-code img {
  margin-left: -178px;
}
.exception-body.accessdenied .exception-panel .exception-icon {
  background-color: #ffb300;
}
.exception-body .exception-panel {
  width: 550px;
  height: 480px;
  background-color: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -275px;
  margin-top: -240px;
  padding: 0;
  text-align: center;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
}
.exception-body .exception-panel .exception-code {
  height: 240px;
  position: relative;
}
.exception-body .exception-panel .exception-code img {
  position: absolute;
  bottom: 0;
  height: 190px;
  left: 50%;
}
.exception-body .exception-panel .exception-detail {
  height: 240px;
  position: relative;
  padding: 60px 0 0 0;
}
.exception-body .exception-panel .exception-detail .exception-icon {
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  display: inline-block;
  z-index: 100;
  position: absolute;
  top: -45px;
  left: 50%;
  margin-left: -45px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.14);
}
.exception-body .exception-panel .exception-detail .exception-icon i {
  font-size: 45px;
  line-height: inherit;
  color: #ffffff;
}
.exception-body .exception-panel .exception-detail h1 {
  font-size: 15px;
  font-weight: bold;
  margin: 10px 0 8px 0;
}
.exception-body .exception-panel .exception-detail p {
  color: #757575;
  margin: 0 0 60px 0;
}

@media (max-width: 640px) {
  .exception-body .exception-panel {
    left: 0;
    margin-left: 0;
    width: 100%;
  }
  .exception-body.error .exception-panel .exception-code img {
    height: 150px;
    margin-left: -150px;
  }
  .exception-body.notfound .exception-panel .exception-code img {
    height: 150px;
    margin-left: -163px;
  }
  .exception-body.accessdenied .exception-panel .exception-code img {
    height: 150px;
    margin-left: -141px;
  }
}
.landing-body {
  background-color: #F5F5F5;
}
.landing-body * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.landing-body p {
  line-height: 1.5;
}
.landing-body #header {
  background-color: #F5F5F5;
}
.landing-body #header > div {
  width: 960px;
  margin: 0 auto;
  height: 90px;
  padding: 15px 0;
}
.landing-body #header > div img {
  height: 60px;
}
.landing-body #header > div #landing-menu {
  float: right;
  list-style-type: none;
  padding: 0;
  margin: 20px 0 0 0;
}
.landing-body #header > div #landing-menu > li {
  display: inline-block;
}
.landing-body #header > div #landing-menu > li a {
  border-bottom: 5px solid transparent;
  color: #616161;
  display: inline-block;
  min-width: 80px;
  text-align: center;
  height: 55px;
  font-size: 15px;
  -moz-transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.landing-body #header > div #landing-menu > li:hover a {
  color: #3f51b5;
  border-color: #3f51b5;
}
.landing-body #header > div #landing-menu-button {
  color: #3f51b5;
  display: none;
  float: right;
  margin-right: 15px;
  margin-top: 5px;
}
.landing-body #header > div #landing-menu-button i {
  font-size: 48px;
}
.landing-body #introduction > div {
  background: url("../images/landing/landing-header.png");
  min-height: 400px;
  color: #ffffff;
  text-align: center;
  padding-top: 120px;
  background-size: cover;
}
.landing-body #introduction > div h1 {
  padding: 0;
  margin: 0 0 20px 0;
}
.landing-body #introduction > div p {
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 40px;
}
.landing-body #introduction > div button {
  min-width: 180px;
}
.landing-body #features > div {
  width: 960px;
  margin: 0 auto;
  padding: 60px 0;
}
.landing-body #features > div .feature-box {
  padding: 30px 15px 30px 0;
}
.landing-body #features > div .feature-box:before, .landing-body #features > div .feature-box:after {
  content: "";
  display: table;
}
.landing-body #features > div .feature-box:after {
  clear: both;
}
.landing-body #features > div .feature-box img {
  float: left;
  margin-right: 30px;
}
.landing-body #features > div .feature-box > div {
  padding: 16px 0;
}
.landing-body #features > div .feature-box > div h3 {
  font-size: 15px;
  margin: 0;
}
.landing-body #features > div .feature-box > div p {
  color: #757575;
  margin: 8px;
}
.landing-body #stats {
  background-color: #212121;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#212121), to(#424242));
  background-image: -webkit-linear-gradient(to bottom, #212121, #424242);
  background-image: -moz-linear-gradient(to bottom, #212121, #424242);
  background-image: -ms-linear-gradient(to bottom, #212121, #424242);
  background-image: -o-linear-gradient(to bottom, #212121, #424242);
  background-image: linear-gradient(to bottom, #212121, #424242);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#212121", endColorstr="#424242");
}
.landing-body #stats > div {
  width: 960px;
  margin: 0 auto;
  padding: 40px 0;
}
.landing-body #stats > div .ui-g-12 {
  padding: 20px;
}
.landing-body #stats > div .stat-box {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  padding: 18px;
  text-align: center;
  color: #e91e63;
}
.landing-body #stats > div .stat-box h3 {
  font-weight: 400;
  margin: 0;
}
.landing-body #stats > div .stat-box p {
  margin: 0;
}
.landing-body #stats > div .stat-box.stat-box-active {
  background-color: #e91e63;
  color: #ffffff;
}
.landing-body #video {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
}
.landing-body #video > div {
  width: 960px;
  margin: 0 auto;
  padding: 60px 0;
}
.landing-body #video > div .video-description {
  padding-top: 80px;
  padding-right: 50px;
}
.landing-body #video > div .video-description h3 {
  font-weight: 400;
  font-size: 24px;
  margin: 0 0 12px 0;
}
.landing-body #video > div .video-description p {
  margin: 0 0 24px 0;
}
.landing-body #pricing > div {
  width: 960px;
  margin: 0 auto;
  padding: 60px 0;
  text-align: center;
}
.landing-body #pricing > div > h2 {
  font-size: 24px;
  font-weight: normal;
  margin: 0 0 12px 0;
}
.landing-body #pricing > div > p {
  color: #757575;
  margin: 0 0 40px 0;
}
.landing-body #pricing > div .pricing-box {
  width: 100%;
  text-align: left;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.landing-body #pricing > div .pricing-box .pricing-header {
  background-color: #212121;
  padding: 16px;
  text-align: center;
}
.landing-body #pricing > div .pricing-box .pricing-header h3 {
  margin: 0;
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  padding-bottom: 4px;
  border-bottom: 1px solid #a7a5a5;
}
.landing-body #pricing > div .pricing-box .pricing-header p {
  color: #a7a5a5;
  margin: 0;
  padding: 4px 0 0 0;
}
.landing-body #pricing > div .pricing-box .pricing-content {
  padding: 16px;
  min-height: 350px;
  position: relative;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
}
.landing-body #pricing > div .pricing-box .pricing-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.landing-body #pricing > div .pricing-box .pricing-content ul li {
  padding: 8px 0;
}
.landing-body #pricing > div .pricing-box .pricing-content ul li i {
  color: #4caf50;
  vertical-align: middle;
  margin-right: 6px;
}
.landing-body #pricing > div .pricing-box .pricing-content button {
  position: absolute;
  min-width: 180px;
  bottom: 20px;
  left: 50%;
  margin-left: -90px;
}
.landing-body #pricing > div .pricing-box .pricing-content .pricing-fee {
  position: absolute;
  top: -24px;
  right: 14px;
  margin-left: -90px;
  text-align: center;
  font-size: 16px;
  width: 48px;
  height: 48px;
  line-height: 48px;
  background-color: #e91e63;
  color: #ffffff;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
}
.landing-body #pricing > div .pricing-box.pricing-box-pro .pricing-header {
  background-color: #e91e63;
  color: #ffffff;
}
.landing-body #pricing > div .pricing-box.pricing-box-pro .pricing-header h3 {
  border-bottom: 1px solid #ffffff;
}
.landing-body #pricing > div .pricing-box.pricing-box-pro .pricing-header p {
  color: #ffffff;
}
.landing-body #pricing > div .pricing-box.pricing-box-pro .pricing-content .pricing-fee {
  background-color: #212121;
  color: #ffffff;
}
.landing-body #contact {
  background-color: #424242;
}
.landing-body #contact > div {
  width: 960px;
  margin: 0 auto;
  padding: 60px 0;
  text-align: center;
}
.landing-body #contact > div > h2 {
  font-size: 24px;
  font-weight: normal;
  margin: 0 0 12px 0;
  color: #f5f5f5;
}
.landing-body #contact > div > p {
  margin: 0 0 40px 0;
  color: #e0e0e0;
}
.landing-body #contact > div .contact-form {
  text-align: left;
}
.landing-body #contact > div .contact-form > div {
  padding: 1em 0.5em;
}
.landing-body #contact > div .contact-form button {
  width: auto;
  min-width: 180px;
  margin-left: 15px;
  margin-top: 40px;
}
.landing-body #contact > div .contact-form .md-inputfield input:focus ~ label,
.landing-body #contact > div .contact-form .md-inputfield input.ui-state-filled ~ label,
.landing-body #contact > div .contact-form .md-inputfield textarea:focus ~ label,
.landing-body #contact > div .contact-form .md-inputfield textarea.ui-state-filled ~ label,
.landing-body #contact > div .contact-form .md-inputfield .md-inputwrapper-focus ~ label,
.landing-body #contact > div .contact-form .md-inputfield .md-inputwrapper-filled ~ label {
  color: #90A4AE;
}
.landing-body #contact > div .contact-form .md-inputfield input:-webkit-autofill ~ label {
  color: #90A4AE;
}
.landing-body #contact > div .contact-form .md-inputfield input:focus {
  border-color: #90A4AE;
}
.landing-body #contact > div .contact-form .md-inputfield input {
  color: #ffffff;
}
.landing-body #footer {
  background-color: #212121;
  color: #ffffff;
}
.landing-body #footer > div {
  width: 960px;
  margin: 0 auto;
  padding: 30px 0;
}
.landing-body #footer > div .footer-logo {
  height: 54px;
  float: left;
  margin-right: 14px;
}
.landing-body #footer > div h4 {
  margin: 0 0 8px 0;
  font-weight: 700;
}
.landing-body #footer > div p {
  margin: 0;
  line-height: 1.5;
}
.landing-body #footer > div p:last-child {
  margin-top: 8px;
}
.landing-body #footer > div i {
  vertical-align: middle;
}
.landing-body #footer > div .footer-social a {
  margin-right: 14px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.landing-body #footer > div .footer-social a img {
  width: 30px;
  height: 30px;
}
.landing-body #footer > div .footer-social a:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

@media screen and (max-width: 64em) {
  .landing-body {
    padding-top: 90px;
  }
  .landing-body #header {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  }
  .landing-body #header > div {
    width: 100%;
    padding-left: 15px;
  }
  .landing-body #header > div #landing-menu-button {
    display: block;
  }
  .landing-body #header > div #landing-menu {
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    animation-duration: 0.5s;
    display: none;
    float: none;
    width: 100%;
    text-align: center;
    background-color: #F5F5F5;
    position: fixed;
    top: 70px;
    left: 0;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  }
  .landing-body #header > div #landing-menu li {
    display: block;
  }
  .landing-body #header > div #landing-menu li a {
    height: auto;
    border-bottom: 0 none;
    padding: 15px;
  }
  .landing-body #header > div #landing-menu.landing-menu-active {
    display: block;
  }
  .landing-body #introduction {
    width: 100%;
  }
  .landing-body #introduction > div h1, .landing-body #introduction > div p {
    padding-left: 15px;
    padding-right: 15px;
  }
  .landing-body #features > div {
    width: 100%;
    padding-left: 15px;
  }
  .landing-body #stats > div {
    width: 100%;
  }
  .landing-body #video > div {
    width: 100%;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
  }
  .landing-body #video > div .video-description {
    padding-top: 0;
    padding-right: 0;
  }
  .landing-body #video > div iframe {
    width: 300px;
    height: 200px;
  }
  .landing-body #pricing > div {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .landing-body #contact > div {
    width: 100%;
    text-align: center;
  }
  .landing-body #contact > div .contact-map {
    text-align: center;
  }
  .landing-body #contact > div .contact-map img {
    width: 100%;
  }
  .landing-body #contact > div .contact-form .ui-g-12 {
    padding: 15px;
  }
  .landing-body #footer > div {
    width: 100%;
  }
  .landing-body #footer > div .ui-g-12 {
    padding-top: 24px;
  }
}
html {
  height: 100%;
}

body {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  color: #212121;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  min-height: 100%;
  background-color: #F5F5F5;
}
body .ajax-loader {
  font-size: 32px;
  color: #7CB342;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.layout-wrapper .layout-sidebar {
  width: 240px;
  height: 100%;
  position: fixed;
  left: -180px;
  top: 0;
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  z-index: 999999;
  background-color: #566e87;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: left 0.3s;
  -o-transition: left 0.3s;
  -webkit-transition: left 0.3s;
  transition: left 0.3s;
  -webkit-box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
  background-image: url("../images/special/bg-moody-light.png");
  background-size: 240px 100%;
  background-repeat: no-repeat;
}
.layout-wrapper .layout-sidebar .ui-scrollpanel {
  border: none;
  background-color: transparent;
  border-radius: 0;
}
.layout-wrapper .layout-sidebar .ui-scrollpanel .ui-scrollpanel-bar {
  background-color: #aaaaaa;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.layout-wrapper .layout-sidebar .ui-scrollpanel .ui-scrollpanel-hidden {
  display: block;
  visibility: hidden;
}
.layout-wrapper .layout-sidebar .ui-scrollpanel .ui-scrollpanel-content {
  overflow-x: hidden;
}
.layout-wrapper .layout-sidebar .ui-scrollpanel .layout-menu {
  padding-bottom: 120px;
}
.layout-wrapper .layout-sidebar .sidebar-logo {
  height: 64px;
  background-color: rgba(36, 50, 59, 0.5);
  padding-top: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.layout-wrapper .layout-sidebar .sidebar-logo img {
  height: 48px;
  margin-left: 12px;
  vertical-align: middle;
}
.layout-wrapper .layout-sidebar .sidebar-logo .sidebar-anchor {
  display: none;
  width: 18px;
  height: 18px;
  border: 2px solid #ffffff;
  background-color: #607D8B;
  vertical-align: middle;
  float: right;
  margin-right: 8px;
  margin-top: 12px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.layout-wrapper .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
  vertical-align: middle;
  font-size: 26px;
}
.layout-wrapper .layout-sidebar .layout-menu {
  list-style-type: none;
  margin: 10px 0 0 0;
  padding: 0;
  width: 258px;
}
.layout-wrapper .layout-sidebar .layout-menu li {
  padding: 4px 30px 4px 10px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.layout-wrapper .layout-sidebar .layout-menu li.active-menuitem > a {
  color: #9CCC65;
}
.layout-wrapper .layout-sidebar .layout-menu li.active-menuitem > a i {
  color: #9CCC65;
}
.layout-wrapper .layout-sidebar .layout-menu li.active-menuitem > a i.layout-submenu-toggler {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.layout-wrapper .layout-sidebar .layout-menu li > a {
  color: #ffffff;
  display: block;
  padding: 10px 10px 10px 10px;
  position: relative;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.layout-wrapper .layout-sidebar .layout-menu li > a:hover {
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff;
}
.layout-wrapper .layout-sidebar .layout-menu li > a:hover i {
  color: #ffffff;
}
.layout-wrapper .layout-sidebar .layout-menu li > a > .menuitem-text {
  display: inline-block;
  max-width: 145px;
  word-break: break-all;
}
.layout-wrapper .layout-sidebar .layout-menu li > a i {
  color: #ffffff;
  float: right;
  width: 20px;
  height: 20px;
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -10px;
}
.layout-wrapper .layout-sidebar .layout-menu li > a i.layout-submenu-toggler {
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  right: 34px;
  display: none;
}
.layout-wrapper .layout-sidebar .layout-menu li > a .menuitem-badge {
  display: none;
  position: absolute;
  right: 54px;
  top: 50%;
  margin-top: -8px;
}
.layout-wrapper .layout-sidebar .layout-menu li ul {
  overflow: hidden;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.layout-wrapper .layout-sidebar .layout-menu li ul li {
  padding: 4px 0;
}
.layout-wrapper .layout-sidebar .layout-menu li ul li a {
  padding-left: 20px;
}
.layout-wrapper .layout-sidebar .layout-menu li ul li ul li a {
  padding-left: 30px;
}
.layout-wrapper .layout-sidebar .layout-menu li ul li ul li ul li a {
  padding-left: 40px;
}
.layout-wrapper .layout-sidebar .layout-menu li ul li ul li ul ul li a {
  padding-left: 50px;
}
.layout-wrapper .layout-sidebar .layout-menu li ul li ul li ul ul ul li a {
  padding-left: 60px;
}
.layout-wrapper .layout-sidebar .layout-menu li ul li ul li ul ul ul ul li a {
  padding-left: 70px;
}
.layout-wrapper .layout-sidebar .layout-menu li ul li ul li ul ul ul ul ul li a {
  padding-left: 80px;
}
.layout-wrapper .layout-sidebar .layout-menu > li > a {
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.layout-wrapper .layout-sidebar .layout-menu > li > a.active-menuitem-routerlink {
  color: #9CCC65;
}
.layout-wrapper .layout-sidebar .layout-menu > li.active-menuitem > a {
  color: #ffffff;
  background-color: #7CB342;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(60, 72, 88, 0.3), 0 7px 10px -5px rgba(60, 72, 88, 0.1);
  -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(60, 72, 88, 0.3), 0 7px 10px -5px rgba(60, 72, 88, 0.1);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(60, 72, 88, 0.3), 0 7px 10px -5px rgba(60, 72, 88, 0.1);
}
.layout-wrapper .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-wrapper .layout-sidebar .layout-menu > li.active-menuitem > ul {
  background-color: rgba(255, 255, 255, 0.2);
  -moz-border-radius-bottomleft: 6px;
  -webkit-border-bottom-left-radius: 6px;
  border-bottom-left-radius: 6px;
  -moz-border-radius-bottomright: 6px;
  -webkit-border-bottom-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.layout-wrapper .layout-sidebar .layout-menu .menuitem-badge {
  float: right;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  text-align: center;
  background-color: #7CB342;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.layout-wrapper .layout-sidebar.layout-sidebar-active {
  left: 0;
}
.layout-wrapper .layout-sidebar.layout-sidebar-active .sidebar-logo img {
  display: inline;
}
.layout-wrapper .layout-sidebar.layout-sidebar-active .sidebar-logo .sidebar-anchor {
  display: inline-block;
}
.layout-wrapper .layout-sidebar.layout-sidebar-active .layout-menu li a i.layout-submenu-toggler {
  display: inline-block;
}
.layout-wrapper .layout-sidebar.layout-sidebar-active .layout-menu li a .menuitem-badge {
  display: inline-block;
}
.layout-wrapper .layout-sidebar.layout-sidebar-dark {
  background-color: #202d35;
  background-image: url("../images/special/bg-moody-dark.png");
}
.layout-wrapper .layout-sidebar.layout-sidebar-dark .layout-menu li > a {
  color: #dee0e3;
}
.layout-wrapper .layout-sidebar.layout-sidebar-dark .layout-menu li > a:hover {
  background-color: #7CB342;
  color: #ffffff;
}
.layout-wrapper .layout-sidebar.layout-sidebar-dark .layout-menu li > a:hover i {
  color: #ffffff;
}
.layout-wrapper .layout-sidebar.layout-sidebar-dark .layout-menu li > a i {
  color: #dee0e3;
}
.layout-wrapper .layout-sidebar.layout-sidebar-dark .layout-menu li > a.active-menuitem-routerlink {
  color: #9CCC65;
}
.layout-wrapper .layout-sidebar.layout-sidebar-dark .layout-menu li.active-menuitem > a {
  color: #9CCC65;
}
.layout-wrapper .layout-sidebar.layout-sidebar-dark .layout-menu li.active-menuitem > a i {
  color: #9CCC65;
}
.layout-wrapper .layout-sidebar.layout-sidebar-dark .layout-menu > li.active-menuitem > a {
  background-color: #7CB342;
  color: #ffffff;
}
.layout-wrapper .layout-sidebar.layout-sidebar-dark .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-wrapper .layout-sidebar.layout-sidebar-dark .layout-menu > li.active-menuitem > ul {
  background-color: rgba(255, 255, 255, 0.15);
}
.layout-wrapper .layout-main {
  margin-left: 60px;
  -moz-transition: margin-left 0.3s;
  -o-transition: margin-left 0.3s;
  -webkit-transition: margin-left 0.3s;
  transition: margin-left 0.3s;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.layout-wrapper .layout-main .layout-topbar {
  height: 64px;
  background-color: #607D8B;
  padding: 16px 42px 16px 24px;
  position: fixed;
  width: calc(100% - 40px);
  -moz-transition: width 0.3s;
  -o-transition: width 0.3s;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 999997;
}
.layout-wrapper .layout-main .layout-topbar .topbar-logo {
  display: none;
}
.layout-wrapper .layout-main .layout-topbar .menu-btn {
  display: none;
  color: #E8EAF6;
  float: left;
}
.layout-wrapper .layout-main .layout-topbar .menu-btn i {
  font-size: 32px;
}
.layout-wrapper .layout-main .layout-topbar .topbar-menu-btn {
  display: none;
  color: #E8EAF6;
  float: right;
}
.layout-wrapper .layout-main .layout-topbar .topbar-menu-btn i {
  font-size: 32px;
}
.layout-wrapper .layout-main .layout-topbar .mobile-logo {
  display: none;
  height: 48px;
  margin-top: -8px;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  margin: 0;
  -webkit-animation-duration: 0s;
  -moz-animation-duration: 0s;
  animation-duration: 0s;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu:before, .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu:after {
  content: "";
  display: table;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu:after {
  clear: both;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu .topbar-badge {
  width: 16px;
  height: 16px;
  text-align: center;
  background-color: #7CB342;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li {
  float: right;
  position: relative;
  margin-left: 20px;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > a {
  color: #E8EAF6;
  position: relative;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > a .topbar-item-name {
  display: none;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > a i {
  font-size: 32px;
  color: #E8EAF6;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > a i:hover {
  color: #c3c9e8;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > a .topbar-badge {
  position: absolute;
  right: -4px;
  top: -24px;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item {
  float: left;
  margin-left: 0;
  padding-top: 4px;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item > a {
  display: inline-block;
  position: relative;
  top: -10px;
  color: #E8EAF6;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item > a .profile-image-wrapper {
  display: inline-block;
  vertical-align: middle;
  border: 2px solid transparent;
  width: 40px;
  height: 40px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item > a .profile-image-wrapper img {
  width: 40px;
  height: 40px;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item > a .profile-name {
  display: inline-block;
  margin-left: 6px;
  vertical-align: middle;
  margin-top: -4px;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item > a:hover .profile-image-wrapper {
  border-color: #7CB342;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item > ul {
  right: auto;
  left: 5px;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item > ul:before {
  left: 8px;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.search-item {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 40px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.search-item input {
  border: 0 none;
  width: 150px;
  padding: 6px 24px 6px 6px;
  background-color: transparent;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #a8bac3, #a8bac3);
  border-width: 0;
  outline: 0 none;
  color: #E8EAF6;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.search-item input:focus label {
  color: #E8EAF6;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.search-item i {
  position: absolute;
  right: 0;
  top: 0;
  color: #E8EAF6;
  font-size: 28px;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.search-item label {
  color: #E8EAF6;
  margin-top: 6px;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.search-item input:focus ~ i {
  color: #ffffff;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > ul {
  position: absolute;
  top: 60px;
  right: 5px;
  display: none;
  width: 250px;
  background-color: #ffffff;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
  list-style-type: none;
  margin: 0;
  padding: 8px 0;
  border-top: 4px solid #607D8B;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > ul a {
  padding: 10px 10px 10px 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  color: #212121;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > ul a i {
  color: #757575;
  margin-right: 8px;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > ul a img {
  margin-right: 8px;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > ul a i, .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > ul a img, .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > ul a span {
  vertical-align: middle;
  display: inline-block;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > ul a .topbar-submenuitem-badge {
  background-color: #7CB342;
  padding: 2px 4px;
  display: block;
  font-size: 12px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #ffffff;
  float: right;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > ul a:hover {
  background-color: #f1f2f7;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > ul a:hover i {
  color: #212121;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > ul:before {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 15px solid #607D8B;
  content: " ";
  position: absolute;
  top: -15px;
  left: 232px;
}
.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.active-topmenuitem > ul {
  display: block;
}
.layout-wrapper .layout-main .layout-breadcrumb {
  background-color: #ffffff;
  -webkit-box-shadow: inset 0 -2px 4px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: inset 0 -2px 4px 0 rgba(0, 0, 0, 0.14);
  box-shadow: inset 0 -2px 4px 0 rgba(0, 0, 0, 0.14);
  min-height: 42px;
  padding-top: 64px;
}
.layout-wrapper .layout-main .layout-breadcrumb:before, .layout-wrapper .layout-main .layout-breadcrumb:after {
  content: "";
  display: table;
}
.layout-wrapper .layout-main .layout-breadcrumb:after {
  clear: both;
}
.layout-wrapper .layout-main .layout-breadcrumb ul {
  margin: 8px 0 0 0;
  padding: 0 0 0 20px;
  list-style: none;
  color: #757575;
  display: inline-block;
}
.layout-wrapper .layout-main .layout-breadcrumb ul li {
  display: inline-block;
  vertical-align: middle;
  color: #757575;
}
.layout-wrapper .layout-main .layout-breadcrumb ul li:nth-child(even) {
  font-size: 20px;
}
.layout-wrapper .layout-main .layout-breadcrumb ul li:first-child(even) {
  color: #607D8B;
}
.layout-wrapper .layout-main .layout-breadcrumb ul li a {
  color: #757575;
}
.layout-wrapper .layout-main .layout-breadcrumb .layout-breadcrumb-options {
  float: right;
  padding: 0px 20px 0 0;
  height: 100%;
}
.layout-wrapper .layout-main .layout-breadcrumb .layout-breadcrumb-options a {
  color: #757575;
  display: inline-block;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.layout-wrapper .layout-main .layout-breadcrumb .layout-breadcrumb-options a:hover {
  background-color: #e8e8e8;
}
.layout-wrapper .layout-main .layout-breadcrumb .layout-breadcrumb-options a i {
  line-height: inherit;
}
.layout-wrapper .layout-main .layout-content {
  padding: 17px 17px 24px 17px;
}
.layout-wrapper .layout-main .layout-main-mask {
  display: none;
}
.layout-wrapper .layout-main .layout-footer {
  padding: 16px 24px;
  border: 0 none;
  border: 1px solid #dbdbdb;
  background: #ffffff;
}
.layout-wrapper .layout-main .layout-footer img {
  margin-top: 5px;
  width: 100px;
}
.layout-wrapper .layout-main .layout-footer .footer-text-right {
  float: right;
  margin-top: 10px;
}
.layout-wrapper .layout-main .layout-footer .footer-text-right span {
  vertical-align: middle;
}

.layout-wrapper-static .layout-sidebar {
  left: 0;
}
.layout-wrapper-static .layout-sidebar .sidebar-logo .sidebar-anchor {
  display: inline-block;
  background-color: #ffffff;
}
.layout-wrapper-static .layout-sidebar .layout-menu li a i.layout-submenu-toggler {
  display: inline-block;
}
.layout-wrapper-static .layout-sidebar .layout-menu li a .menuitem-badge {
  display: inline-block;
}
.layout-wrapper-static .layout-main {
  margin-left: 240px;
}
.layout-wrapper-static .layout-main .layout-topbar {
  width: calc(100% - 240px);
}

.layout-wrapper-static-restore .layout-sidebar {
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}

@media (max-width: 1024px) {
  .layout-wrapper .layout-sidebar {
    left: -240px;
  }
  .layout-wrapper .layout-sidebar .sidebar-logo .sidebar-anchor {
    display: none !important;
  }
  .layout-wrapper .layout-main {
    margin-left: 0;
    left: 0;
    -moz-transition: left 0.3s;
    -o-transition: left 0.3s;
    -webkit-transition: left 0.3s;
    transition: left 0.3s;
    -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  }
  .layout-wrapper .layout-main .layout-topbar {
    width: 100%;
    text-align: center;
    padding: 16px 24px;
  }
  .layout-wrapper .layout-main .layout-topbar:before, .layout-wrapper .layout-main .layout-topbar:after {
    content: "";
    display: table;
  }
  .layout-wrapper .layout-main .layout-topbar:after {
    clear: both;
  }
  .layout-wrapper .layout-main .layout-topbar .menu-btn {
    display: inline-block;
  }
  .layout-wrapper .layout-main .layout-topbar .topbar-menu-btn {
    display: inline-block;
  }
  .layout-wrapper .layout-main .layout-topbar .mobile-logo {
    display: inline;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu {
    display: none;
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    animation-duration: 0.5s;
    text-align: left;
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu:before {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 15px solid #607D8B;
    content: " ";
    position: absolute;
    top: -15px;
    left: 232px;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active {
    position: fixed;
    top: 75px;
    right: 30px;
    width: 250px;
    display: block;
    padding: 8px 0;
    background-color: #ffffff;
    border-top: 4px solid #607D8B;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active > li {
    float: none;
    display: block;
    margin: 0;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active > li > a {
    padding: 8px 14px;
    display: block;
    color: #212121;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active > li > a:hover {
    background-color: #e8e8e8;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active > li > a:hover i {
    color: #212121;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active > li > a i {
    color: #757575;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active > li > a .topbar-item-name {
    display: inline-block;
    vertical-align: middle;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active > li > a .topbar-badge {
    position: static;
    float: right;
    margin-top: 4px;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active > li > ul {
    position: static;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    padding: 0;
    width: 100%;
    border-top: 0 none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active > li > ul:before {
    display: none;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active > li > ul a {
    padding-left: 28px;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active > li.profile-item img {
    width: 24px;
    height: 24px;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu li a {
    font-size: 14px;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu li a i {
    font-size: 24px;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu li.search-item input {
    background-image: linear-gradient(to bottom, #607D8B, #607D8B), linear-gradient(to bottom, #b5b5b5, #b5b5b5);
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu li.search-item {
    padding: 8px 14px;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu li.search-item input {
    padding: 2px;
    border-width: 0;
    border-style: solid;
    color: #212121;
    margin-left: 28px;
    width: 85%;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu li.search-item input:focus {
    border-width: 0;
    width: 85%;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu li.search-item input:focus ~ i {
    color: #607D8B;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu li.search-item input:focus ~ label {
    color: #607D8B;
    top: -15px;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu li.search-item i {
    color: #757575;
    right: auto;
    left: 0px;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu li.search-item label {
    color: #757575;
    left: 32px;
    margin-top: 0;
  }
  .layout-wrapper.layout-wrapper-active {
    overflow: hidden;
  }
  .layout-wrapper.layout-wrapper-active .layout-sidebar {
    left: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  .layout-wrapper.layout-wrapper-active .layout-sidebar .layout-menu li a i.layout-submenu-toggler {
    display: inline-block;
  }
  .layout-wrapper.layout-wrapper-active .layout-sidebar .layout-menu li a .menuitem-badge {
    display: inline-block;
  }
  .layout-wrapper.layout-wrapper-active .layout-main {
    position: fixed;
    left: 240px;
    width: calc(100%);
    -webkit-box-shadow: inset 3px 0px 6px 1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 3px 0px 6px 1px rgba(0, 0, 0, 0.3);
    box-shadow: inset 3px 0px 6px 1px rgba(0, 0, 0, 0.3);
  }
  .layout-wrapper.layout-wrapper-active .layout-main .layout-topbar {
    -webkit-box-shadow: inset 3px 0px 6px 1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 3px 0px 6px 1px rgba(0, 0, 0, 0.3);
    box-shadow: inset 3px 0px 6px 1px rgba(0, 0, 0, 0.3);
  }
  .layout-wrapper.layout-wrapper-active .layout-main .layout-breadcrumb {
    -webkit-box-shadow: inset 3px 0px 6px 1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 3px 0px 6px 1px rgba(0, 0, 0, 0.3);
    box-shadow: inset 3px 0px 6px 1px rgba(0, 0, 0, 0.3);
  }
  .layout-wrapper.layout-wrapper-active .layout-main .layout-breadcrumb .layout-breadcrumb-options {
    display: none;
  }
  .layout-wrapper.layout-wrapper-active .layout-main-mask {
    z-index: 999998;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #424242;
    display: block;
    opacity: 0.5;
    filter: alpha(opacity=50);
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  body.hidden-overflow {
    overflow: hidden;
  }
}
.layout-rtl.layout-wrapper .layout-sidebar {
  left: auto;
  right: -180px;
  -moz-transition: right 0.3s;
  -o-transition: right 0.3s;
  -webkit-transition: right 0.3s;
  transition: right 0.3s;
  direction: rtl;
}
.layout-rtl.layout-wrapper .layout-sidebar .sidebar-logo {
  direction: rtl;
}
.layout-rtl.layout-wrapper .layout-sidebar .sidebar-logo img {
  margin-left: 0px;
  margin-right: 12px;
}
.layout-rtl.layout-wrapper .layout-sidebar .sidebar-logo .sidebar-anchor {
  float: left;
  margin-right: 0px;
  margin-left: 8px;
}
.layout-rtl.layout-wrapper .layout-sidebar .layout-menu {
  direction: rtl;
}
.layout-rtl.layout-wrapper .layout-sidebar .layout-menu li {
  padding: 4px 10px 4px 30px;
}
.layout-rtl.layout-wrapper .layout-sidebar .layout-menu li > a i {
  float: left;
  right: auto;
  left: 10px;
}
.layout-rtl.layout-wrapper .layout-sidebar .layout-menu li > a i.layout-submenu-toggler {
  right: auto;
  left: 34px;
}
.layout-rtl.layout-wrapper .layout-sidebar .layout-menu li > a .menuitem-badge {
  right: auto;
  left: 54px;
}
.layout-rtl.layout-wrapper .layout-sidebar .layout-menu li ul li {
  padding: 4px 0px;
}
.layout-rtl.layout-wrapper .layout-sidebar .layout-menu li ul li a {
  padding-right: 20px;
  padding-left: 0px;
}
.layout-rtl.layout-wrapper .layout-sidebar .layout-menu li ul li ul li a {
  padding-right: 30px;
  padding-left: 0px;
}
.layout-rtl.layout-wrapper .layout-sidebar .layout-menu li ul li ul li ul li a {
  padding-right: 40px;
  padding-left: 0px;
}
.layout-rtl.layout-wrapper .layout-sidebar .layout-menu li ul li ul li ul ul li a {
  padding-right: 50px;
  padding-left: 0px;
}
.layout-rtl.layout-wrapper .layout-sidebar .layout-menu li ul li ul li ul ul ul li a {
  padding-right: 60px;
  padding-left: 0px;
}
.layout-rtl.layout-wrapper .layout-sidebar .layout-menu li ul li ul li ul ul ul ul li a {
  padding-right: 70px;
  padding-left: 0px;
}
.layout-rtl.layout-wrapper .layout-sidebar .layout-menu li ul li ul li ul ul ul ul ul li a {
  padding-right: 80px;
  padding-left: 0px;
}
.layout-rtl.layout-wrapper .layout-sidebar .layout-menu .menuitem-badge {
  float: left;
  margin-right: 0px;
  margin-left: 6px;
}
.layout-rtl.layout-wrapper .layout-sidebar.layout-sidebar-active {
  left: auto;
  right: 0px;
}
.layout-rtl.layout-wrapper .layout-sidebar .ui-scrollpanel .ui-scrollpanel-content {
  overflow-x: hidden;
  padding: 0;
}
.layout-rtl.layout-wrapper .layout-main {
  margin-left: 0px;
  margin-right: 60px;
  -moz-transition: margin-right 0.3s;
  -o-transition: margin-right 0.3s;
  -webkit-transition: margin-right 0.3s;
  transition: margin-right 0.3s;
}
.layout-rtl.layout-wrapper .layout-main .layout-topbar .menu-btn {
  float: right;
}
.layout-rtl.layout-wrapper .layout-main .layout-topbar .topbar-menu-btn {
  float: left;
}
.layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li {
  float: left;
  margin-left: 0px;
  margin-right: 20px;
}
.layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > a .topbar-badge {
  left: -4px;
  right: auto;
}
.layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item {
  float: right;
  margin-left: 0px;
  margin-right: 0px;
}
.layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item > a .profile-name {
  margin-left: 0px;
  margin-right: 6px;
  margin-top: 13px;
  float: left;
}
.layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item > ul {
  left: auto;
  right: 5px;
  direction: rtl;
  text-align: right;
}
.layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item > ul:before {
  left: auto;
  right: 8px;
}
.layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.search-item {
  direction: rtl;
}
.layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.search-item input {
  padding: 6px 6px 6px 24px;
}
.layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.search-item i {
  right: auto;
  left: 0;
}
.layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.search-item label {
  right: 5px;
  left: auto;
}
.layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > ul {
  left: 5px;
  right: auto;
  direction: rtl;
  text-align: right;
}
.layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > ul a i, .layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > ul a img {
  margin-right: 0px;
  margin-left: 8px;
}
.layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > ul a .topbar-submenuitem-badge {
  float: left;
}
.layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > ul:before {
  left: auto;
  right: 232px;
}
.layout-rtl.layout-wrapper .layout-main .layout-breadcrumb {
  direction: rtl;
}
.layout-rtl.layout-wrapper .layout-main .layout-breadcrumb ul {
  padding: 0 20px 0 0;
}
.layout-rtl.layout-wrapper .layout-main .layout-breadcrumb .layout-breadcrumb-options {
  float: left;
  padding: 0px 0px 0 20px;
}
.layout-rtl.layout-wrapper .layout-main .layout-footer {
  direction: rtl;
}
.layout-rtl.layout-wrapper .layout-main .layout-footer .footer-text-right {
  float: left;
  margin-top: 10px;
}
.layout-rtl.layout-wrapper-static .layout-sidebar {
  left: auto;
  right: 0;
}
.layout-rtl.layout-wrapper-static .layout-main {
  margin-left: 0px;
  margin-right: 240px;
}
.layout-rtl.layout-wrapper-static-restore .layout-sidebar {
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
@media (max-width: 1024px) {
  .layout-rtl.layout-wrapper .layout-sidebar {
    left: auto;
    right: -240px;
  }
  .layout-rtl.layout-wrapper .layout-main {
    margin-right: 0px;
    margin-left: 0px;
    left: auto;
    right: 0;
    -moz-transition: right 0.3s;
    -o-transition: right 0.3s;
    -webkit-transition: right 0.3s;
    transition: right 0.3s;
  }
  .layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu {
    direction: rtl;
    text-align: right;
  }
  .layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu:before {
    right: 232px;
    left: auto;
  }
  .layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active {
    left: 30px;
    right: auto;
  }
  .layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active > li {
    float: none;
    margin: 0px;
  }
  .layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active > li > a i {
    margin-right: 0px;
    margin-left: 8px;
  }
  .layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active > li > a .topbar-badge {
    float: left;
  }
  .layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active > li > ul a {
    padding-left: 0px;
    padding-right: 28px;
  }
  .layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item > a .profile-name {
    float: none;
  }
  .layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu li.search-item input {
    margin-left: 0px;
    margin-right: 28px;
    padding: 2px 2px 1px 2px;
  }
  .layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu li.search-item i {
    left: auto;
    right: 0px;
  }
  .layout-rtl.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu li.search-item label {
    right: 32px;
    left: auto;
  }
  .layout-rtl.layout-wrapper.layout-wrapper-active .layout-sidebar {
    right: 0;
    left: auto;
  }
  .layout-rtl.layout-wrapper.layout-wrapper-active .layout-main {
    left: auto;
    right: 240px;
  }
  .layout-rtl.layout-wrapper.layout-wrapper-active .layout-main-mask {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1025px) {
  .layout-wrapper.layout-menu-horizontal .layout-sidebar {
    width: 100%;
    height: auto;
    top: 64px;
    left: 0;
    z-index: 99;
    background-image: url("../images/special/bg-moody-light-horizontal.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-color: #080A10;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .sidebar-logo {
    display: none;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel {
    overflow: visible;
    border: none;
    background: transparent;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .ui-scrollpanel-wrapper {
    overflow: visible;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .ui-scrollpanel-wrapper .ui-scrollpanel-content {
    overflow: visible;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu {
    margin: 0;
    padding-bottom: 0;
    width: 100%;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li {
    width: auto;
    padding: 0;
    position: relative;
    float: left;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li > a {
    height: 44px;
    padding-top: 12px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li > a .menuitem-text {
    vertical-align: middle;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li > a i {
    float: none;
    position: static;
    vertical-align: middle;
    margin-top: 0;
    top: auto;
    right: auto;
    margin-right: 5px;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li > a i.layout-submenu-toggler {
    display: inline-block;
    margin-top: 2px;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li.active-menuitem > a {
    color: #ffffff;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li.active-menuitem > a:hover {
    color: #ffffff;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li.active-menuitem > a:hover i {
    color: #ffffff;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li > ul {
    top: 44px;
    left: 0;
    width: 230px;
    position: absolute;
    padding: 0;
    margin: 0;
    z-index: 100;
    overflow: auto;
    max-height: 450px;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li > ul li a {
    padding-left: 40px;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li > ul li a:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li > ul li a i {
    float: none;
    left: 10px;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li > ul li a i:last-child {
    right: 10px;
    left: auto;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li > ul li a .layout-submenu-toggler {
    display: block;
    left: auto;
    right: 10px;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li > ul li ul li a {
    padding-left: 50px;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li > ul li ul li a:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li > ul li ul li a i:first-child {
    left: 20px;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li > ul li ul ul li a {
    padding-left: 60px;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li > ul li ul ul li a:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li > ul li ul ul li a i:first-child {
    left: 30px;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu > li.active-menuitem > ul {
    background-color: #2b3d4c;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu li a:hover {
    background-color: rgba(255, 255, 255, 0.4);
    color: #ffffff;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu li a:hover i {
    color: #ffffff;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar .ui-scrollpanel .layout-menu li a .menuitem-badge {
    left: 18px;
    top: 15px;
    display: block;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar.layout-sidebar-dark {
    background-color: #0e161e;
    background-image: url("../images/special/bg-moody-dark-horizontal.png");
    background-color: #0A1118;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar.layout-sidebar-dark .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.4);
    color: #ffffff;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar.layout-sidebar-dark .layout-menu > li > a:hover i {
    color: #ffffff;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar.layout-sidebar-dark .layout-menu > li > ul li a:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar.layout-sidebar-dark .layout-menu li a:hover {
    color: #ffffff;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar.layout-sidebar-dark .layout-menu li a:hover i {
    color: #ffffff;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar.layout-sidebar-dark .layout-menu li.active-menuitem > a:hover {
    color: #ffffff;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar.layout-sidebar-dark .layout-menu li.active-menuitem > a:hover i {
    color: #ffffff;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar.layout-sidebar-dark .layout-menu > li.active-menuitem > a {
    color: #ffffff;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar.layout-sidebar-dark .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
  .layout-wrapper.layout-menu-horizontal .layout-sidebar.layout-sidebar-dark .layout-menu > li.active-menuitem > ul {
    background-color: #0e161e;
  }
  .layout-wrapper.layout-menu-horizontal .layout-main {
    margin-left: 0px;
  }
  .layout-wrapper.layout-menu-horizontal .layout-topbar {
    width: 100%;
  }
  .layout-wrapper.layout-menu-horizontal .layout-topbar .topbar-logo {
    float: left;
    margin-top: -10px;
    margin-right: 20px;
    display: inline-block;
  }
  .layout-wrapper.layout-menu-horizontal .layout-topbar .topbar-logo img {
    height: 56px;
    vertical-align: middle;
  }
  .layout-wrapper.layout-menu-horizontal .layout-topbar .topbar-logo .app-name {
    color: #ffffff;
    font-size: 26px;
  }
  .layout-wrapper.layout-menu-horizontal .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item {
    float: right;
    margin-left: 20px;
  }
  .layout-wrapper.layout-menu-horizontal .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item > ul {
    left: auto;
    right: 105px;
  }
  .layout-wrapper.layout-menu-horizontal .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item > ul:before {
    left: 232px;
  }
  .layout-wrapper.layout-menu-horizontal .layout-breadcrumb {
    padding-top: 108px;
  }
  .layout-wrapper.layout-menu-horizontal.layout-rtl .layout-main {
    margin-right: 0px;
  }
  .layout-wrapper.layout-menu-horizontal.layout-rtl .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item {
    float: left;
    margin-right: 20px;
    margin-left: auto;
  }
  .layout-wrapper.layout-menu-horizontal.layout-rtl .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item > ul {
    left: 105px;
    right: auto;
  }
  .layout-wrapper.layout-menu-horizontal.layout-rtl .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item > ul:before {
    left: auto;
    right: 232px;
  }
  .layout-wrapper.layout-menu-horizontal.layout-rtl .layout-main .layout-topbar .topbar-logo {
    float: right;
    margin-right: auto;
    margin-left: 20px;
  }
  .layout-wrapper.layout-menu-horizontal.layout-rtl .layout-sidebar {
    -moz-transition: right 0s;
    -o-transition: right 0s;
    -webkit-transition: right 0s;
    transition: right 0s;
  }
  .layout-wrapper.layout-menu-horizontal.layout-rtl .layout-sidebar .layout-menu > li {
    float: right;
  }
  .layout-wrapper.layout-menu-horizontal.layout-rtl .layout-sidebar .layout-menu > li > a i {
    margin-right: auto;
    margin-left: 5px;
  }
  .layout-wrapper.layout-menu-horizontal.layout-rtl .layout-sidebar .layout-menu > li > ul {
    left: auto;
  }
  .layout-wrapper.layout-menu-horizontal.layout-rtl .layout-sidebar .layout-menu > li > ul li a {
    padding-right: 40px;
    padding-left: 0px;
  }
  .layout-wrapper.layout-menu-horizontal.layout-rtl .layout-sidebar .layout-menu > li > ul li a i {
    right: 10px;
    left: auto;
  }
  .layout-wrapper.layout-menu-horizontal.layout-rtl .layout-sidebar .layout-menu > li > ul li a i:last-child {
    left: 10px;
    right: auto;
  }
  .layout-wrapper.layout-menu-horizontal.layout-rtl .layout-sidebar .layout-menu > li > ul li a .layout-submenu-toggler {
    right: auto;
    left: 10px;
  }
  .layout-wrapper.layout-menu-horizontal.layout-rtl .layout-sidebar .layout-menu > li > ul li ul li a {
    padding-right: 50px;
    padding-left: 0px;
  }
  .layout-wrapper.layout-menu-horizontal.layout-rtl .layout-sidebar .layout-menu > li > ul li ul li a i:first-child {
    right: 20px;
    left: auto;
  }
  .layout-wrapper.layout-menu-horizontal.layout-rtl .layout-sidebar .layout-menu > li > ul li ul ul li a {
    padding-right: 60px;
    padding-left: 0px;
  }
  .layout-wrapper.layout-menu-horizontal.layout-rtl .layout-sidebar .layout-menu > li > ul li ul ul li a i:first-child {
    right: 30px;
    left: auto;
  }
  .layout-wrapper.layout-menu-horizontal.layout-rtl .layout-sidebar .layout-menu li a .menuitem-badge {
    right: 18px;
    left: auto;
  }
  .layout-wrapper.layout-menu-horizontal.layout-rtl .layout-sidebar .layout-menu li a i:last-child {
    margin-right: 3px;
  }
}
/* Add your customizations of layout here */
